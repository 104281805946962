var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cu-menu pt-2 pb-2 pl-3 rounded pointer mb-3 d-flex flex-column",class:{
        'bg-black': _vm.isHovered,
        'bg-white border border-gray-500': !_vm.isHovered,
        'not-allowed': _vm.button === 'Coming Soon',
    },on:{"click":_vm.goToPage,"mouseenter":function($event){_vm.isHovered = true},"mouseleave":function($event){_vm.isHovered = false}}},[_c('div',{staticClass:"d-flex"},[_c('div',[_c('ba-base-icon',{staticClass:"mt-2 mr-2 title-icon",class:{
                    'text-white': _vm.isHovered,
                    'text-black': !_vm.isHovered,
                },attrs:{"name":_vm.logo}})],1),_c('div',[_c('div',{staticClass:"font-weight-normal cu-menu__title mt-2",class:{
                    'text-white': _vm.isHovered,
                    'text-black': !_vm.isHovered,
                }},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"w-75 cu-menu__description",class:{
                    'text-gray-700': _vm.isHovered,
                    'text-black-50': !_vm.isHovered,
                }},[_vm._v(" "+_vm._s(_vm.description)+" ")])])]),_c('div',{staticClass:"d-flex align-items-center justify-content-end mt-auto"},[_c('div',{staticClass:"mr-2 text-sm-1",class:{
                'text-gray-700': _vm.isHovered,
                'text-blue-900': !_vm.isHovered,
            }},[_vm._v(" "+_vm._s(_vm.button)+" ")]),_c('div',[_c('ba-base-icon',{attrs:{"name":_vm.img,"height":"15px"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }