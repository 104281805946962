



























































































































































































































































































































































































































































































































import Vue from "vue";
import { formatMoney, formatDate } from "@/utils/helpers";
import { mapGetters } from "vuex";

export default Vue.extend({
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        report: {
            type: Object,
            default: () => ({}),
        },
        flaggedSections: {
            type: Object,
            default: () => {
                return {
                    auditor: {
                        flagReason: null,
                        isFlagged: false,
                    },
                    registrar: {
                        flagReason: null,
                        isFlagged: false,
                    },
                    boardConsultant: {
                        flagReason: null,
                        isFlagged: false,
                    },
                    governanceConsultant: {
                        flagReason: null,
                        isFlagged: false,
                    },
                };
            },
        },
    },
    computed: {
        ...mapGetters({
            user: "auth/getUserData",
        }),
        showButtons(): boolean {
            return (
                this.report?.reportInformation?.currentDeskUser?.email ===
                this.user.email
            );
        },
    },
    methods: {
        formatMoney,
        formatDate,
        sendFlaggedProps(description: string, comment: string) {
            this.$emit("clicked", { description, comment });
        },
        selectSection(val: any) {
            this.$emit("selectSection", {
                section: val,
                sectionType: "Stakeholder",
            });
        },
    },
});
