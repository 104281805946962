











































import Vue from "vue";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default Vue.extend({
    name: "FlagPrincipleModal",
    props: {
        section: {
            type: Object,
            required: true,
        },
        sectionType: {
            type: String,
            required: true,
        },
        modalId: {
            type: String,
            required: true,
        },
    },
    watch: {
        section(val) {
            this.setFlaggedReason(val);
        },
    },
    computed: {
        buttonText(): string {
            return this.section.flagReason ? "Update Comment" : "Add Comment";
        },
        commentAction(): any {
            return {
                Documentation: {
                    action: async ({ id, body }: { id: string; body: any }) => {
                        const res = await this.commentDocument({
                            id,
                            body,
                        });
                        return res;
                    },
                },
	            Certification: {
		            action: async ({ id, body }: { id: string; body: any }) => {
			            const res = await this.commentSignatory({
				            id,
				            body,
			            });
			            return res;
		            },
	            },
	            Structure: {
		            action: async ({ id, body }: { id: string; body: any }) => {
			            const res = await this.commentPosition({
				            id,
				            body,
			            });
			            return res;
		            },
	            },
	            Company: {
		            action: async ({ id, body }: { id: string; body: any }) => {
			            const res = await this.commentStandard({
				            id,
				            body,
			            });
			            return res;
		            },
	            },
	            Stakeholder: {
		            action: async ({ id, body }: { id: string; body: any }) => {
			            const res = await this.commentStandard({
				            id,
				            body,
			            });
			            return res;
		            },
	            },
            };
        },
    },
    data() {
        return {
            isSaving: false,
            form: {
                flagReason: "",
            },
        };
    },
    mounted() {
        this.setFlaggedReason(this.section);
    },
    methods: {
        ...mapActions({
            commentPrinciple: "reports/commentPrinciple",
            commentStandard: "reports/flagStandard",
            commentPosition: "reports/flagPosition",
            commentSignatory: "reports/flagSignatory",
            commentDocument: "reports/flagDocument",
        }),
        setFlaggedReason(sectionData: any) {
            if (sectionData.id) {
                this.form.flagReason = sectionData.flagReason;
            }
        },
        async savePrincipleComment() {
            this.isSaving = true;

            const body = {
                flagReason: this.form.flagReason,
                entryId: this.section.id,
            };
            try {
                this.isSaving = true;
                const { id } = this.$route.params;
                const res = await this.commentAction[this.sectionType].action({
                    id,
                    body,
                });
                this.$bvToast.toast(res.message, {
                    title: "Success!",
                    variant: "success",
                    solid: true,
                });
                this.$emit("completed");
                this.resetData();
            } catch (error) {
                console.log(error);
                this.isSaving = false;
            } finally {
                this.isSaving = false;
            }
        },
        resetData() {
            this.form = {
                flagReason: "",
            };
        },
    },
    validations(): any {
        return {
            form: {
                flagReason: {
                    required,
                },
            },
        };
    },
});
