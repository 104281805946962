var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ba-loader-wrapper',{attrs:{"isLoading":_vm.isLoading}},[_c('div',{staticClass:"py-3 px-4"},[_c('ba-detail-section',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("General")]},proxy:true},{key:"description",fn:function(){return [_vm._v(" Please provide general information about your Company. "),(
                        _vm.flaggedSections.general &&
                        _vm.flaggedSections.general.flagReason
                    )?_c('div',{staticClass:"mt-4 d-flex align-items-center"},[(_vm.showButtons)?_c('b-button',{staticClass:"text-white px-3 py-2 mr-2",staticStyle:{"font-size":"11px"},attrs:{"variant":"info"},on:{"click":function($event){return _vm.selectSection(_vm.flaggedSections.general)}}},[_vm._v(" "+_vm._s(_vm.flaggedSections.general.flagReason ? "Update" : "Add")+" Comment ")]):_vm._e(),(_vm.flaggedSections.general.flagReason)?_c('b-button',{staticClass:"text-black px-3 py-2",staticStyle:{"font-size":"11px"},attrs:{"variant":"warning"},on:{"click":function($event){return _vm.sendFlaggedProps(
                                _vm.flaggedSections.general.flaggedDescription,
                                _vm.flaggedSections.general.flagReason
                            )}}},[_vm._v("View Comment")]):_vm._e()],1):_vm._e()]},proxy:true},{key:"body",fn:function(){return [_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-3"},[_c('ba-subheader',[_c('div',{staticClass:"text-sm-2"},[_vm._v("NAME")])]),_c('div',{staticClass:"text-black text-sm-3"},[_vm._v(" "+_vm._s(_vm.report.companyDetails ? _vm.report.companyDetails.name : "")+" ")])],1)]),_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"mb-3"},[_c('ba-subheader',[_c('div',{staticClass:"text-sm-2"},[_vm._v("Website")])]),_c('div',{staticClass:"text-black text-sm-3"},[_vm._v(" "+_vm._s(_vm.report.companyDetails ? _vm.report.companyDetails.website : "")+" ")])],1)]),_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"mb-3 text-primary pointer"},[_c('div',{staticClass:"text-primary text-right text-sm-3"},[_c('a',{attrs:{"href":_vm.report.companyDetails
                                            ? _vm.report.companyDetails.website
                                            : '',"target":"_blank"}},[_vm._v("Open Website")])])])]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-3"},[_c('ba-subheader',[_c('div',{staticClass:"text-sm-2"},[_vm._v(" Date of incorporation ")])]),_c('div',{staticClass:"text-black text-sm-3"},[_vm._v(" "+_vm._s(_vm.report.companyDetails ? _vm.formatDate( _vm.report.companyDetails .incorporationDate ) : "")+" ")])],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-3"},[_c('ba-subheader',[_c('div',{staticClass:"text-sm-2"},[_vm._v("RC Number")])]),_c('div',{staticClass:"text-black text-sm-3"},[_vm._v(" "+_vm._s(_vm.report.companyDetails ? _vm.report.companyDetails.rcNumber : "")+" ")])],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-3"},[_c('ba-subheader',[_c('div',{staticClass:"text-sm-2"},[_vm._v(" Description Of Services Rendered ")])]),_c('div',{staticClass:"text-black text-sm-3"},[_vm._v(" "+_vm._s(_vm.report.companyDetails ? _vm.report.companyDetails .serviceDescription : "")+" ")])],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-3"},[_c('ba-subheader',[_c('div',{staticClass:"text-sm-2"},[_vm._v("Business Sector")])]),_c('div',{staticClass:"text-black text-sm-3"},[_vm._v(" "+_vm._s(_vm.report.companyDetails ? _vm.report.companyDetails .businessSector : "")+" ")])],1)])],1)]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }