var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ba-loader-wrapper',{attrs:{"isLoading":_vm.isLoading}},[_c('div',{staticClass:"py-3 px-4"},[_c('ba-detail-section',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("External Auditor")]},proxy:true},{key:"description",fn:function(){return [_vm._v(" Please provide details of your Company's External Auditors. "),_c('div',{staticClass:"mt-4 d-flex align-items-center"},[(_vm.showButtons)?_c('b-button',{staticClass:"text-white px-3 py-2 mr-2",staticStyle:{"font-size":"11px"},attrs:{"variant":"info"},on:{"click":function($event){return _vm.selectSection(_vm.flaggedSections.auditor)}}},[_vm._v(" "+_vm._s(_vm.flaggedSections.auditor.flagReason ? "Update" : "Add")+" Comment ")]):_vm._e(),(_vm.flaggedSections.auditor.flagReason)?_c('b-button',{staticClass:"text-black px-3 py-2",staticStyle:{"font-size":"11px"},attrs:{"variant":"warning"},on:{"click":function($event){return _vm.sendFlaggedProps(
                                'View comment on External auditor',
                                _vm.flaggedSections.auditor.flagReason
                            )}}},[_vm._v("View Comment")]):_vm._e()],1)]},proxy:true},{key:"body",fn:function(){return [(_vm.report.auditors.length > 0)?_c('div',_vm._l((_vm.report.auditors),function(auditor,ix){return _c('b-row',{key:ix},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-3"},[_c('ba-subheader',[_c('div',{staticClass:"text-sm-2"},[_vm._v("NAME")])]),_c('div',{staticClass:"text-black text-sm-3"},[_vm._v(" "+_vm._s(auditor.name || "Not Provided")+" ")])],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-3"},[_c('ba-subheader',[_c('div',{staticClass:"text-sm-2"},[_vm._v(" Appointment Date ")])]),_c('div',{staticClass:"text-black text-sm-3"},[_vm._v(" "+_vm._s(_vm.formatDate( auditor.dateOfAppointment ) || "Not Provided")+" ")])],1)]),(
                                ix > 0 && ix != _vm.report.auditors.length - 1
                            )?_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]):_vm._e()],1)}),1):_c('b-col',{staticClass:"m-0 p-0"},[_c('div',{staticClass:"text-black text-sm-3"},[_vm._v("None provided")])])]},proxy:true}])}),_c('hr'),_c('ba-detail-section',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Registrar")]},proxy:true},{key:"description",fn:function(){return [_vm._v(" Registrar company details "),_c('div',{staticClass:"mt-4 d-flex align-items-center"},[(_vm.showButtons)?_c('b-button',{staticClass:"text-white px-3 py-2 mr-2",staticStyle:{"font-size":"11px"},attrs:{"variant":"info"},on:{"click":function($event){return _vm.selectSection(_vm.flaggedSections.registrar)}}},[_vm._v(" "+_vm._s(_vm.flaggedSections.registrar.flagReason ? "Update" : "Add")+" Comment ")]):_vm._e(),(_vm.flaggedSections.registrar.flagReason)?_c('b-button',{staticClass:"text-black px-3 py-2",staticStyle:{"font-size":"11px"},attrs:{"variant":"warning"},on:{"click":function($event){return _vm.sendFlaggedProps(
                                'View comment on External registrar',
                                _vm.flaggedSections.registrar.flagReason
                            )}}},[_vm._v("View Comment")]):_vm._e()],1)]},proxy:true},{key:"body",fn:function(){return [(_vm.report.registrarCompanies.length > 0)?_c('div',_vm._l((_vm.report.registrarCompanies),function(registrar,ix){return _c('b-row',{key:ix},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-3"},[_c('ba-subheader',[_c('div',{staticClass:"text-sm-2"},[_vm._v("NAME")])]),_c('div',{staticClass:"text-black text-sm-3"},[_vm._v(" "+_vm._s(registrar.name)+" ")])],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-3"},[_c('ba-subheader',[_c('div',{staticClass:"text-sm-2"},[_vm._v(" Email address ")])]),_c('div',{staticClass:"text-black text-sm-3"},[_vm._v(" "+_vm._s(registrar.email)+" ")])],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-3"},[_c('ba-subheader',[_c('div',{staticClass:"text-sm-2"},[_vm._v(" Phone Number ")])]),_c('div',{staticClass:"text-black text-sm-3"},[_vm._v(" "+_vm._s(registrar.phoneNumber || "Not Provided")+" ")])],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-3"},[_c('ba-subheader',[_c('div',{staticClass:"text-sm-2"},[_vm._v("Address")])]),_c('div',{staticClass:"text-black text-sm-3"},[_vm._v(" "+_vm._s(registrar.address || "Not provided")+" ")])],1)]),(
                                ix > 0 &&
                                ix != _vm.report.registrarCompanies.length - 1
                            )?_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]):_vm._e()],1)}),1):_vm._e(),(
                        _vm.report.registrarCompanies.length === 0 &&
                        !_vm.report.reportInformation.isRegistrarSecretary
                    )?_c('b-col',{staticClass:"m-0 p-0"},[_c('div',{staticClass:"text-black text-sm-3"},[_vm._v("None provided")])]):_vm._e(),(_vm.report.reportInformation.isRegistrarSecretary)?_c('b-form-checkbox',{staticClass:"d-flex align-items-center",attrs:{"id":"checkbox-registrar-view","name":"checkbox-registrar-view","switch":"","disabled":""},model:{value:(_vm.report.reportInformation.isRegistrarSecretary),callback:function ($$v) {_vm.$set(_vm.report.reportInformation, "isRegistrarSecretary", $$v)},expression:"report.reportInformation.isRegistrarSecretary"}},[_vm._v("Company secretary is registrar")]):_vm._e()]},proxy:true}])}),_c('hr'),_c('ba-detail-section',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Board Evaluation Consultant")]},proxy:true},{key:"description",fn:function(){return [_vm._v(" Provide details of Person, Firm or Officer conducting an Evaluation of the Company's Board and Board Committee structures, standards and practices. "),_c('div',{staticClass:"mt-4 d-flex align-items-center"},[(_vm.showButtons)?_c('b-button',{staticClass:"text-white px-3 py-2 mr-2",staticStyle:{"font-size":"11px"},attrs:{"variant":"info"},on:{"click":function($event){return _vm.selectSection(_vm.flaggedSections.boardConsultant)}}},[_vm._v(" "+_vm._s(_vm.flaggedSections.boardConsultant.flagReason ? "Update" : "Add")+" Comment ")]):_vm._e(),(_vm.flaggedSections.boardConsultant.flagReason)?_c('b-button',{staticClass:"text-black px-3 py-2",staticStyle:{"font-size":"11px"},attrs:{"variant":"warning"},on:{"click":function($event){return _vm.sendFlaggedProps(
                                'View comment on External board consultant',
                                _vm.flaggedSections.boardConsultant.flagReason
                            )}}},[_vm._v("View Comment")]):_vm._e()],1)]},proxy:true},{key:"body",fn:function(){return [(_vm.report.boardConsultants.length > 0)?_c('div',_vm._l((_vm.report.boardConsultants),function(boardConsultant,ix){return _c('b-row',{key:ix},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-3"},[_c('ba-subheader',[_c('div',{staticClass:"text-sm-2"},[_vm._v("NAME")])]),_c('div',{staticClass:"text-black text-sm-3"},[_vm._v(" "+_vm._s(boardConsultant.name || "Not Provided")+" ")])],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-3"},[_c('ba-subheader',[_c('div',{staticClass:"text-sm-2"},[_vm._v(" Email address ")])]),_c('div',{staticClass:"text-black text-sm-3"},[_vm._v(" "+_vm._s(boardConsultant.email || "Not Provided")+" ")])],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-3"},[_c('ba-subheader',[_c('div',{staticClass:"text-sm-2"},[_vm._v(" Phone Number ")])]),_c('div',{staticClass:"text-black text-sm-3"},[_vm._v(" "+_vm._s(boardConsultant.phoneNumber || "Not Provided")+" ")])],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-3"},[_c('ba-subheader',[_c('div',{staticClass:"text-sm-2"},[_vm._v("Address")])]),_c('div',{staticClass:"text-black text-sm-3"},[_vm._v(" "+_vm._s(boardConsultant.primaryAddress || "Not Provided")+" ")])],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-3"},[_c('ba-subheader',[_c('div',{staticClass:"text-sm-2"},[_vm._v(" Appointment Date ")])]),_c('div',{staticClass:"text-black text-sm-3"},[_vm._v(" "+_vm._s(_vm.formatDate( boardConsultant.dateOfAppointment ) || "Not Provided")+" ")])],1)]),(
                                ix > 0 &&
                                ix != _vm.report.boardConsultants.length - 1
                            )?_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]):_vm._e()],1)}),1):_c('b-col',{staticClass:"m-0 p-0"},[_c('div',{staticClass:"text-black text-sm-3"},[_vm._v("None provided")])])]},proxy:true}])}),_c('hr'),_c('ba-detail-section',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Governance Evaluation Consultant")]},proxy:true},{key:"description",fn:function(){return [_vm._v(" Provide details of Person, Firm or Officer conducting an Evaluation of the Company's Board and Board Committee structures, standards and practices. "),_c('div',{staticClass:"mt-4 d-flex align-items-center"},[(_vm.showButtons)?_c('b-button',{staticClass:"text-white px-3 py-2 mr-2",staticStyle:{"font-size":"11px"},attrs:{"variant":"info"},on:{"click":function($event){return _vm.selectSection(
                                _vm.flaggedSections.governanceConsultant
                            )}}},[_vm._v(" "+_vm._s(_vm.flaggedSections.governanceConsultant.flagReason ? "Update" : "Add")+" Comment ")]):_vm._e(),(
                            _vm.flaggedSections.governanceConsultant.flagReason
                        )?_c('b-button',{staticClass:"text-black px-3 py-2",staticStyle:{"font-size":"11px"},attrs:{"variant":"warning"},on:{"click":function($event){return _vm.sendFlaggedProps(
                                'View comment on External governance consultant',
                                _vm.flaggedSections.governanceConsultant
                                    .flagReason
                            )}}},[_vm._v("View Comment")]):_vm._e()],1)]},proxy:true},{key:"body",fn:function(){return [(_vm.report.governanceConsultants.length > 0)?_c('div',_vm._l((_vm.report.governanceConsultants),function(governanceConsultant,ix){return _c('b-row',{key:ix},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-3"},[_c('ba-subheader',[_c('div',{staticClass:"text-sm-2"},[_vm._v("NAME")])]),_c('div',{staticClass:"text-black text-sm-3"},[_vm._v(" "+_vm._s(governanceConsultant.name || "Not Provided")+" ")])],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-3"},[_c('ba-subheader',[_c('div',{staticClass:"text-sm-2"},[_vm._v(" Email address ")])]),_c('div',{staticClass:"text-black text-sm-3"},[_vm._v(" "+_vm._s(governanceConsultant.email || "Not Provided")+" ")])],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-3"},[_c('ba-subheader',[_c('div',{staticClass:"text-sm-2"},[_vm._v(" Phone Number ")])]),_c('div',{staticClass:"text-black text-sm-3"},[_vm._v(" "+_vm._s(governanceConsultant.phoneNumber || "Not Provided")+" ")])],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-3"},[_c('ba-subheader',[_c('div',{staticClass:"text-sm-2"},[_vm._v("Address")])]),_c('div',{staticClass:"text-black text-sm-3"},[_vm._v(" "+_vm._s(governanceConsultant.primaryAddress || "Not Provided")+" ")])],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-3"},[_c('ba-subheader',[_c('div',{staticClass:"text-sm-2"},[_vm._v(" Appointment Date ")])]),_c('div',{staticClass:"text-black text-sm-3"},[_vm._v(" "+_vm._s(_vm.formatDate( governanceConsultant.dateOfAppointment ) || "Not Provided")+" ")])],1)]),(
                                ix > 0 &&
                                ix !=
                                    _vm.report.governanceConsultants.length - 1
                            )?_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]):_vm._e()],1)}),1):_c('b-col',{staticClass:"m-0 p-0"},[_c('div',{staticClass:"text-black text-sm-3"},[_vm._v("None provided")])])]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }