






























































import Vue from "vue";
import { mapGetters, mapState, mapActions } from "vuex";
import { SETTINGS, HOME } from "../../routes/endpoints";

export default Vue.extend({
    data(): {
        isLoading: boolean;
        profile: {
            name: string;
            email: string;
        };
        business: {
            name: string;
            email: string;
        };
    } {
        return {
            isLoading: false,
            profile: {
                name: "",
                email: "",
            },
            business: {
                name: "",
                email: "",
            },
        };
    },
    computed: {
        ...mapState("reports", ["reports"]),
        ...mapGetters({ profileData: "auth/getUserData" }),
        ...mapState("users", {
            reviewers: (state: any) =>
                state.levels?.find(
                    (level: any) => level.category === "ReportReviewer",
                )?.users,
            signatories: (state: any) =>
                state.levels?.find(
                    (level: any) => level.category === "ReportSignatory",
                )?.users,
        }),
        isPersonalProfileComplete(): boolean {
            return this.profileData.isProfileUpdated;
        },
        isCompanyProfileComplete(): boolean {
            return this.profileData.isCompanyProfileUpdated;
        },
        canCreateReport(): boolean {
            return (
                this.profileData?.roles?.includes("Admin") ||
                this.profileData?.roles?.includes("ReportCreator")
            );
        },
        hasAReviewer(): boolean {
            return this.reviewers?.length > 0;
        },
        hasASignatory(): boolean {
            return this.signatories?.length > 0;
        },
        hasAReport(): boolean {
            return this.reports.length > 0;
        },
        buttonText(): string {
            let text = "Start";
            if (!this.isCompanyProfileComplete) {
                text = "Complete Company Profile";
            } else if (this.isCompanyProfileComplete && !this.hasAReviewer) {
                text = "Add Reviewer";
            } else if (
                this.isCompanyProfileComplete &&
                this.hasAReviewer &&
                !this.hasASignatory
            ) {
                text = "Add Signatory";
            } else if (
                this.isCompanyProfileComplete &&
                this.hasAReviewer &&
                this.hasASignatory &&
                !this.hasAReport
            ) {
                text = "Create Report";
            } else if (
                this.isCompanyProfileComplete &&
                this.hasAReviewer &&
                this.hasASignatory &&
                this.hasAReport
            ) {
                text = "Congratulations! You're all set";
            }

            return text;
        },
        progress(): number {
            let count = 0;

            if (this.isCompanyProfileComplete) {
                count += 1;
            }
            if (this.hasAReviewer) {
                count += 1;
            }
            if (this.hasASignatory) {
                count += 1;
            }
            if (this.hasAReport) {
                count += 1;
            }

            return count;
        },
    },
    methods: {
        takeAction(): void {
            if (!this.isCompanyProfileComplete) {
                this.$router.push({
                    name: SETTINGS.BUSINESS.NAME,
                    query: {
                        "onboarding-step": "1",
                    },
                });
            } else if (this.isCompanyProfileComplete && !this.hasAReviewer) {
                this.$router.push({
                    name: SETTINGS.USER_ACCESS.NAME,
                    query: {
                        "onboarding-step": "2",
                        "tab": "reviewers-tab",
                    },
                });
            } else if (
                this.isCompanyProfileComplete &&
                this.hasAReviewer &&
                !this.hasASignatory
            ) {
                this.$router.push({
                    name: SETTINGS.USER_ACCESS.NAME,
                    query: {
                        "onboarding-step": "3",
                        "tab": "signatories-tab",
                    },
                });
            } else if (
                this.isCompanyProfileComplete &&
                this.hasAReviewer &&
                !this.hasAReport
            ) {
                this.$router.push({
                    name: HOME.REPORTS.NAME,
                    query: {
                        "activate-modal": "true",
                        "onboarding-step": "4",
                    },
                });
            }
        },
    },
    mounted() {
        console.log(this.profileData);
    },
});
