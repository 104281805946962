











































import Vue from "vue";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default Vue.extend({
    name: "FlagPrincipleModal",
    props: {
        principle: {
            type: Object,
            required: true,
        },
        question: {
            type: Object,
            required: true,
        },
        modalId: {
            type: String,
            required: true,
        },
    },
    watch: {
        question(val) {
            this.setFlaggedReason(val);
        },
    },
    computed: {
        buttonText(): string {
            return this.question.flagReason ? "Update Comment" : "Add Comment";
        },
    },
    data() {
        return {
            isSaving: false,
            form: {
                flagReason: "",
            },
        };
    },
    mounted() {
        this.setFlaggedReason(this.question);
    },
    methods: {
        ...mapActions({
            commentPrinciple: "reports/commentPrinciple",
        }),
        setFlaggedReason(questionData: any) {
            if (questionData.flagReason) {
                this.form.flagReason = questionData.flagReason;
            }
        },
        async savePrincipleComment() {
            this.isSaving = true;

            const body = {
                flagReason: this.form.flagReason,
                entryId: this.question.id,
            };
            try {
                this.isSaving = true;
                const res = await this.commentPrinciple({
                    id: this.$route.params.id as string,
                    body,
                });

                this.$bvToast.toast(res.message, {
                    title: "Success!",
                    variant: "success",
                    solid: true,
                });
                this.$emit("completed");
                this.resetData();
            } catch (error) {
                this.isSaving = false;
            } finally {
                this.isSaving = false;
            }
        },
        resetData() {
            this.form = {
                flagReason: "",
            };
        },
    },
    validations(): any {
        return {
            form: {
                flagReason: {
                    required,
                },
            },
        };
    },
});
