










































import Vue from "vue";
import { required } from "vuelidate/lib/validators";

export default Vue.extend({
    name: "CompliancePreviewModal",
    props: {
        id: {
            type: String,
            default: "",
        },
        description: {
            type: String,
            default: "Please provide feedback",
        },
        title: {
            type: String,
            default: "",
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isCompliant: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            form: {
                comment: "",
            },
        };
    },
    validations(): any {
        return {
            form: {
                comment: {
                    required,
                },
            },
        };
    },
});
