var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-items-center"},[_c('p',{staticClass:"text-black h6 mb-0 mr-3"},[_vm._v(" Question "),_c('span',{staticClass:"text-lowercase mx-1"},[_vm._v(" "+_vm._s(_vm.romanize(_vm.principleInput.orderIndex))+" ")]),(_vm.principleInput.innerOrderIndex > 0)?_c('span',[_vm._v(" ("+_vm._s(String.fromCharCode( 96 + _vm.principleInput.innerOrderIndex ))+") ")]):_vm._e()])]),_c('div',{staticClass:"d-flex justify-content-between",class:_vm.mappedInputModel.type.includes(_vm.IQuestionType.BOOLEAN)
                ? 'justify-content-between'
                : ''},[_c('div',{staticClass:"text-sm-3",domProps:{"innerHTML":_vm._s(_vm.mappedInputModel.entry)}}),(
                _vm.mappedInputModel.type.includes(_vm.IQuestionType.BOOLEAN) ||
                _vm.mappedInputModel.type.includes(
                    _vm.IQuestionType.BOOLEAN_AND_LONGTEXT
                ) ||
                _vm.mappedInputModel.type.includes(
                    _vm.IQuestionType.BOOLEAN_AND_LONGTEXT_AND_MEDIA
                )
            )?_c('b-form-checkbox',{staticClass:"ml-4",attrs:{"switch":"","size":"lg","checked":_vm.mappedInputModel.booleanValue,"disabled":""}},[_c('span',{staticClass:"text-md",class:_vm.mappedInputModel.booleanValue
                        ? 'text-success'
                        : 'text-danger'},[_vm._v(_vm._s(_vm.mappedInputModel.booleanValue ? "Yes" : "No"))])]):_vm._e()],1),(
            _vm.mappedInputModel.type.includes(_vm.IQuestionType.LONGTEXT) ||
            _vm.mappedInputModel.type.includes(_vm.IQuestionType.DATEPICKER)
        )?_c('div',{staticClass:"text-black h6 mt-3"},[_vm._v("Response")]):_vm._e(),(
            _vm.mappedInputModel.type.includes(_vm.IQuestionType.LONGTEXT) ||
            _vm.mappedInputModel.type.includes(_vm.IQuestionType.TEXT) ||
            _vm.mappedInputModel.type.includes(
                _vm.IQuestionType.TEXT_AND_DATEPICKER
            ) ||
            _vm.mappedInputModel.type.includes(
                _vm.IQuestionType.BOOLEAN_AND_LONGTEXT
            ) ||
            _vm.mappedInputModel.type.includes(
                _vm.IQuestionType.BOOLEAN_AND_LONGTEXT_AND_MEDIA
            ) ||
            _vm.mappedInputModel.type.includes(_vm.IQuestionType.LONGTEXT_AND_MEDIA)
        )?_c('div',{staticClass:"border-2 text-sm-3 mt-2 px-2 py-1"},[_c('i',{staticClass:"mr-2"},[_vm._v("Explanation:")]),_vm._v(" "+_vm._s(_vm.mappedInputModel.stringValue || "No Input provided")+" ")]):_vm._e(),(_vm.mappedInputModel.type.includes(_vm.IQuestionType.NUMBER))?_c('div',{staticClass:"border-2 text-sm-3 mt-2 px-2 py-1"},[_c('i',{staticClass:"mr-2"},[_vm._v("Answer:")]),_vm._v(" "+_vm._s(_vm.mappedInputModel.numericValue || "No Input provided")+" ")]):_vm._e(),(
            _vm.mappedInputModel.type.includes(_vm.IQuestionType.DATEPICKER) ||
            _vm.mappedInputModel.type.includes(
                _vm.IQuestionType.TEXT_AND_DATEPICKER
            ) ||
            _vm.mappedInputModel.type.includes(
                _vm.IQuestionType.DROPDOWN_AND_DATEPICKER
            )
        )?_c('div',{staticClass:"border-2 text-sm-3 mt-2 px-2 py-1"},[_c('i',{staticClass:"mr-2"},[_vm._v("Date:")]),_vm._v(" "+_vm._s(_vm.formatDate(_vm.mappedInputModel.dateValue, _vm.DateTime.DATE_SHORT) || "No Input provided")+" ")]):_vm._e(),(
            _vm.mappedInputModel.type.includes(_vm.IQuestionType.MEDIA) ||
            _vm.mappedInputModel.type.includes(
                _vm.IQuestionType.BOOLEAN_AND_LONGTEXT_AND_MEDIA
            ) ||
            _vm.mappedInputModel.type.includes(_vm.IQuestionType.LONGTEXT_AND_MEDIA) || true
        )?_c('div',{staticClass:"mt-2"},_vm._l((_vm.mappedInputModel.attatchments),function(attachment,idx){return _c('ba-file-input',{key:idx,attrs:{"noLabel":"","noDivider":"","readonly":"","fileName":attachment.fileName,"fileId":attachment.docUploadId,"size":attachment.size,"label":''}})}),1):_vm._e(),_c('div',{staticClass:"mt-4 d-flex"},[(_vm.showButtons)?_c('div',{staticClass:"d-flex"},[(!_vm.mappedInputModel.isFlagged)?_c('ba-button',{staticClass:"text-white px-3 py-2 mr-2",staticStyle:{"font-size":"11px"},attrs:{"variant":"danger","text":"Flag","loading":_vm.isLoading},on:{"click":function($event){$event.preventDefault();return _vm.resolveFlagPrinciple(true)}}}):_c('ba-button',{staticClass:"text-white px-3 py-2 mr-2",staticStyle:{"font-size":"11px"},attrs:{"loading":_vm.isLoading,"variant":"success","text":"Resolve Flag (Approve)"},on:{"click":function($event){$event.preventDefault();return _vm.resolveFlagPrinciple(false)}}}),_c('b-button',{staticClass:"text-white px-3 py-2 mr-2",staticStyle:{"font-size":"11px"},attrs:{"variant":"info"},on:{"click":function($event){$event.preventDefault();return _vm.toggleFlagPrinciple({ flag: false })}}},[_vm._v(" "+_vm._s(_vm.mappedInputModel.flagReason ? "Update" : "Add")+" Comment ")])],1):_vm._e(),(_vm.mappedInputModel.flagReason)?_c('b-button',{staticClass:"text-white px-3 py-2",staticStyle:{"font-size":"11px"},attrs:{"variant":"warning"},on:{"click":function($event){_vm.$emit('inputModel', {
                    description: ("View comment on Question " + (_vm.romanize(
                        _vm.principleInput.orderIndex
                    )) + " " + (_vm.principleInput.innerOrderIndex > 0
                            ? String.fromCharCode(
                                  96 + _vm.principleInput.innerOrderIndex
                              )
                            : '')),
                    comment: _vm.mappedInputModel.flagReason,
                })}}},[_vm._v("View Comment")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }