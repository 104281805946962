























































import Vue from "vue";
import html2pdf from "html2pdf.js";

export default Vue.extend({
    name: "FeedbackPreviewModal",
    props: {
        id: {
            type: String,
            default: "",
        },
        text: {
            type: String,
            default: "",
        },

        isLoading: {
            type: Boolean,
            default: false,
        },
        report: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isDownloading: false,
        };
    },
    methods: {
        async downloadFeedback() {
            this.isDownloading = true;
            try {
                const page = document.getElementById("pdf-preview-report");

                const pdfOptions = {
                    margin: 1,
                    filename: `${
                        this.report?.reportCode
                    } - Regulator Report Feedback Preview ${new Date()
                        .toISOString()
                        .slice(0, 19)}.pdf`,
                    image: { type: "jpeg", quality: 0.98 },
                    html2canvas: {
                        dpi: 192,
                        letterRendering: true,
                    },
                    jsPDF: {
                        unit: "in",
                        format: "letter",
                        orientation: "portrait",
                    },
                };

                await html2pdf().set(pdfOptions).from(page).save();

                this.$bvToast.toast("Preview downloaded successfully!", {
                    title: "Success!",
                    variant: "success",
                    solid: true,
                });

                this.$bvModal.hide(`modal-feedback-preview-${this.report.id}`);
            } catch (error) {
                //
            } finally {
                this.isDownloading = false;
            }
        },
    },
});
