























import Vue from "vue";
// eslint-disable-next-line import/no-cycle
import { Slide } from "vue-burger-menu";
import { mapActions, mapState } from "vuex";

export default Vue.extend({
    components: {
        Slide,
    },
    data(): {
        breadcrumbs: {
            text: string;
            to: { name: string };
        }[];
        isMenuOpen: boolean;
        isLoading: boolean;
    } {
        return {
            breadcrumbs: [],
            isMenuOpen: false,
            isLoading: false,
        };
    },
    computed: {
        ...mapState("reports", ["reports"]),
    },
    methods: {
        ...mapActions({
            fetchUsers: "users/fetchUsers",
            fetchLevels: "users/fetchLevels",
            fetchProfile: "auth/fetchProfile",
            // fetchClaims: "auth/fetchClaims",
            fetchCompanyProfile: "company/fetchProfile",
            fetchReportList: "reports/fetchReportList",
        }),
        changeRoute() {
            this.breadcrumbs = (this.$router as any).activedRoutes
                .map((e: any) => e.meta?.breadcrumb)
                .filter((e: any) => e) as [];
        },
        handleMenuClick(e: any) {
            e.preventDefault();
            e.stopPropagation();
            (this.$refs.slide as any).$children[0].openMenu();
            this.isMenuOpen = true;
        },
        closeMenu() {
            (this.$refs.slide as any).$children[0].closeMenu();
        },
        async setupApp() {
            try {
                this.isLoading = true;
                // await this.fetchUsers();
                // await this.fetchLevels();
                await this.fetchProfile();
                // await this.fetchCompanyProfile();
                // await this.fetchClaims();
                if (this.reports.length === 0) {
                    await this.fetchReportList();
                }
            } catch (e) {
                this.isLoading = false;

                this.$bvToast.toast("Error", {
                    title: "Error while fetching data!",
                    variant: "error",
                    solid: true,
                });
            } finally {
                this.isLoading = false;
            }
        },
    },
    watch: {},
    mounted() {
        this.setupApp();
    },
});
