













































































import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        report: {
            type: Object,
            default: () => ({}),
        },
        flaggedSections: {
            type: Object,
            default: () => {
                return {
                    otherDocuments: {
                        flagReason: null,
                        isFlagged: false,
                    },
                    primaryOperatingLicense: {
                        flagReason: null,
                        isFlagged: false,
                    },
                    attendanceRegister: {
                        flagReason: null,
                        isFlagged: false,
                    },
                };
            },
        },
    },
    data() {
        return {
            mapped: {
                // "Proof of registered address": "proofOfAddress",
                "Attendance Register": "attendanceRegister",
                "Primary Operating License": "primaryOperatingLicense",
                "Other documents": "otherDocuments",
                // "Proof of business License": "proofOfBusinessLicense",
                // "Minute Book": "minuteBook",
                // "Memorandum & Articles of Association": "memorandum",
            },
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/getUserData",
        }),
        showButtons(): boolean {
            return (
                this.report?.reportInformation?.currentDeskUser?.email ===
                this.user.email
            );
        },
    },
    methods: {
        sendFlaggedProps(description: string, comment: string) {
            this.$emit("clicked", { description, comment });
        },
        selectSection(val: any) {
            this.$emit("selectSection", {
                section: val,
                sectionType: "Documentation",
            });
        },
    },
});
