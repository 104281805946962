var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ba-loader-wrapper',{attrs:{"isLoading":_vm.isLoading}},[_c('div',{staticClass:"py-3 px-4"},[_c('ba-detail-section',{attrs:{"isFlagged":false},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Declaration")]},proxy:true},{key:"description",fn:function(){return undefined},proxy:true},{key:"body",fn:function(){return [_vm._v(" We hereby confirm that this declaration together with every information or detail provided in this are true and correct to the best of our knowledge. ")]},proxy:true}])}),_vm._l((_vm.mappedSignatoriesPositions),function(signatoryPosition,ix){return _c('div',{key:ix,staticClass:"mx-0 mt-5"},[_c('ba-detail-section',{attrs:{"isFlagged":false},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(signatoryPosition.position))]},proxy:true},{key:"description",fn:function(){return [_c('div',{staticClass:"mt-4 d-flex align-items-center"},[(_vm.showButtons)?_c('b-button',{staticClass:"text-white px-3 py-2 mr-2",staticStyle:{"font-size":"11px"},attrs:{"variant":"info"},on:{"click":function($event){return _vm.selectSection(signatoryPosition)}}},[_vm._v(" "+_vm._s(signatoryPosition.details.flagReason ? "Update" : "Add")+" Comment ")]):_vm._e(),(
                                signatoryPosition.details.flagReason &&
                                !_vm.isPreview
                            )?_c('b-button',{staticClass:"text-white px-3 py-2 mr-2",staticStyle:{"font-size":"11px"},attrs:{"variant":"warning"},on:{"click":function($event){return _vm.sendFlaggedProps(
                                    signatoryPosition.flaggedDescription,
                                    signatoryPosition.details.flagReason
                                )}}},[_vm._v("View Comment")]):_vm._e()],1)]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"text-black h6"},[_vm._v("Name")]),_c('div',{staticClass:"text-sm-3"},[_vm._v(" "+_vm._s(signatoryPosition.details.fullName)+" ")]),(!_vm.isPreview)?_c('div',[_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"p-3 signature-preview-wrapper primary-backdrop"},[_c('div',{staticClass:"signature-preview d-flex align-items-center",style:({
                                        backgroundImage: ("url(" + (_vm.getSignatureUrl(
                                            signatoryPosition.details
                                                .signatureUploadId
                                        )) + ")"),
                                    })},[(
                                            !signatoryPosition.details
                                                .signatureUploadId
                                        )?_c('p',{staticClass:"mb-0"},[_vm._v(" Not Provided ")]):_vm._e()])])])]):_vm._e(),(!_vm.isPreview)?_c('div',{staticClass:"text-sm-3 mt-3 d-flex align-items-center",class:{
                            'text-warning':
                                !signatoryPosition.details
                                    .signatureUploadId,
                        }},[_c('span',{staticClass:"text-black mr-2"},[_vm._v("Status:")]),_c('b-badge',{staticClass:"px-3 py-2 text-md",class:{
                                'text-white':
                                    signatoryPosition.details
                                        .signatureUploadId,
                                'text-black':
                                    !signatoryPosition.details
                                        .signatureUploadId,
                            },attrs:{"pill":"","variant":signatoryPosition.details.signatureUploadId
                                    ? 'success'
                                    : 'warning'}},[_vm._v(" "+_vm._s(signatoryPosition.details.signatureUploadId ? "Signed" : "Awaiting...")+" ")])],1):_vm._e()]},proxy:true}],null,true)})],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }