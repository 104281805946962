

















import Vue from "vue";

export default Vue.extend({
    name: "FlaggedCommentModal",
    props: {
        description: {
            type: String,
        },
        comment: {
            type: String,
            default: "No Comment Found",
        },
    },
});
