









































































































































































































































import Vue from "vue";
import { mapGetters, mapState } from "vuex";

export default Vue.extend({
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        report: {
            type: Object,
            default: () => ({}),
        },
        flaggedSections: {
            type: Object,
            default: () => {
                return {
                    committees: {
                        flagReason: null,
                        isFlagged: false,
                    },
                    complianceOfficers: {
                        flagReason: null,
                        isFlagged: false,
                    },
                    executives: {
                        flagReason: null,
                        isFlagged: false,
                    },
                    secretarys: {
                        flagReason: null,
                        isFlagged: false,
                    },
                    contactPersons: {
                        flagReason: null,
                        isFlagged: false,
                    },
                    boardOfDirectors: {
                        flagReason: null,
                        isFlagged: false,
                    },
                    investorRelations: {
                        flagReason: null,
                        isFlagged: false,
                    },
                };
            },
        },
    },
    data() {
        return {
            structurePositions: [
                {
                    positionDescription:
                        "Person(s) responsible for the execution of the agreement and set out as point of contact in a company",
                    positionName: "Company contact persons",
                    id: ["S2", "COMPANYCONTACTPERSONS"],
                    flaggedName: "contactPersons",
                    flaggedDescription:
                        "View comment on company contact person",
                },
                {
                    positionDescription:
                        "Individual(s) who ensures that a company complies with its outside regulatory and legal requirements as well as internal policies and bylaws.",
                    positionName: "Compliance Officer(s)",
                    id: ["S5", "COMPLIANCEOFFICER(S)"],
                    flaggedName: "complianceOfficers",
                    flaggedDescription: "View comment on compliance officers",
                },
                {
                    positionDescription:
                        "Individual(s) responsible for bridging the communication gap between a company's corporate management and its investors",
                    positionName: "Investor Relations Officer(s)",
                    id: ["S6", "INVESTORRELATIONSOFFICER(S)"],
                    flaggedName: "investorRelations",
                    flaggedDescription: "View comment on investor relations",
                },
                {
                    positionDescription:
                        "Elected group of individuals that represent shareholders",
                    positionName: "Board of Directors",
                    id: ["S3", "BOARDOFDIRECTORS"],
                    flaggedName: "boardOfDirectors",
                    flaggedDescription: "View comment on board of directors",
                },
                {
                    positionDescription:
                        "Individual(s) who oversee business activities as fulfilling organizational goals, strategic planning development and overall decision making",
                    positionName: "Executive Management and Leadership",
                    id: ["S1", "EXECUTIVEMANAGEMENTANDLEADERSHIP"],
                    flaggedName: "executives",
                    flaggedDescription:
                        "View comment on executive management and leadership",
                },
                {
                    positionDescription:
                        "Individual(s) responsible for circulating agendas and other documentation to directors, shareholders and auditors within the company.",
                    positionName: "Company Secretary(s)",
                    id: ["S4", "COMPANYSECRETARY(S)"],
                    flaggedName: "secretarys",
                    flaggedDescription: "View comment on company secretary",
                },
            ],
        };
    },
    computed: {
        ...mapState("users", ["users"]),
        ...mapGetters({
            user: "auth/getUserData",
        }),
        mappedStructurePositions(): any {
            return this.structurePositions.map((position: any) => {
                const positionVal =
                    this.report?.structure?.structurePositions.find(
                        (val: any) => position.id.includes(val.identity),
                    );
                return {
                    ...positionVal,
                    positionName: position.positionName,
                    positionDescription: position.positionDescription,
                    flaggedName: position.flaggedName,
                    flaggedDescription: position.flaggedDescription,
                };
            });
        },
        showButtons(): boolean {
            return (
                this.report?.reportInformation?.currentDeskUser?.email ===
                this.user.email
            );
        },
    },
    methods: {
        getUser(id: string) {
            return this.report?.users?.find((user: any) => user.userId === id);
        },
        sendFlaggedProps(description: string, comment: string) {
            this.$emit("clicked", { description, comment });
        },
        selectSection(val: any, sectionType = "Structure") {
            this.$emit("selectSection", {
                section: val,
                sectionType,
            });
        },
    },
});
