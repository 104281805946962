

































































































































































































import Vue from "vue";
import FeedbackPreviewModal from "@/app/components/modals/FeedbackPreviewModal.vue";
import { timeAgo, formatDate } from "@/utils/helpers";
import { DateTime } from "luxon";
import { HOME } from "../../routes/endpoints";
import ReportService from "../../../api/report-service";

enum IReportStatus {
    DRAFT = 0,
    SIGNED = 12,
    FINALISED = 6,
    PROCESSING = 7,
    AWAITING_SIGNING = 3,
    REGULATOR_APPROVAL_IN_PROGRESS = 5,
}

export default Vue.extend({
    props: {
        "report": {
            type: Object,
            required: true,
        },
        "show-actions": {
            type: Boolean,
            default: true,
        },
    },
    components: {
        FeedbackPreviewModal,
    },
    data() {
        return {
            HOME,
            IReportStatus,
            DateTime,
            isDeleting: false,
            isLoading: false,
            previewText: "",
        };
    },
    computed: {
        itemOptions() {
            const val: any[] = [
                {
                    text: "View History",
                    action: (item: any) => {
                        this.goTo({
                            name: HOME.VIEW_REPORT_HISTORY.NAME,
                            params: { id: item.id },
                        });
                    },
                },
            ];

            if (this.report.reportStatus === IReportStatus.FINALISED) {
                val.push({
                    text: "Preview Feedback",
                    action: (item: any) => {
                        this.fetchFeedback();
                        this.$bvModal.show(`modal-feedback-preview-${item.id}`);
                    },
                });
            }

            return val;
        },
        dateLabel() {
            let res = "Date";
            if (this.report.reportStatus === IReportStatus.DRAFT)
                res = "Date Created";
            else if (this.report.reportStatus === IReportStatus.SIGNED)
                res = "Date Submitted Internally";
            else if (this.report.reportStatus === IReportStatus.FINALISED)
                res = "Date Submitted To Regulator";
            return res;
        },
        dateValue() {
            let res = "Date";
            if (this.report.reportStatus === IReportStatus.DRAFT)
                res = formatDate(this.report.dateCreated, DateTime.DATE_SHORT);
            else if (this.report.reportStatus === IReportStatus.SIGNED)
                res = formatDate(
                    this.report.dateSubmittedInternally,
                    DateTime.DATE_SHORT,
                );
            else if (this.report.reportStatus === IReportStatus.FINALISED)
                res = formatDate(
                    this.report.dateSentToRegulator,
                    DateTime.DATE_SHORT,
                );
            return formatDate(this.report.dateCreated, DateTime.DATETIME_SHORT);
        },
        reportStatus() {
            let res = "";

            if (
                this.report.reportStatus ===
                    IReportStatus.REGULATOR_APPROVAL_IN_PROGRESS &&
                this.report.regulatorApprovalStatus === 1
            )
                res = "Pending";
            if (
                this.report.reportStatus ===
                    IReportStatus.REGULATOR_APPROVAL_IN_PROGRESS &&
                this.report.regulatorApprovalStatus === 1 &&
                this.report.lastActionStatus === 5
            )
                res = "Pending - Review Declined";
            else if (this.report.reportStatus === IReportStatus.FINALISED)
                res = "Finalised";
            else if (this.report.regulatorApprovalStatus === 4)
                res = "Rejected";

            return res;
        },
        badgeColor() {
            let res = { bg: "blue-100", text: "primary" };
            if (this.report.reportStatus === IReportStatus.FINALISED)
                res = { bg: "blue-100", text: "primary" };
            // else if (
            //     (this.report.reportStatus === IReportStatus.FINALISED &&
            //         this.report.regulatorApprovalStatus === 3) ||
            //     this.report.regulatorApprovalStatus === 4
            // )
            //     res = { bg: "red-10", text: "danger" };
            else if (
                this.report.reportStatus ===
                IReportStatus.REGULATOR_APPROVAL_IN_PROGRESS
            )
                res = { bg: "yellow-50", text: "yellow-100" };

            return res;
        },
    },
    methods: {
        timeAgo,
        formatDate,
        goTo({
            name,
            params,
            query,
        }: {
            name: string;
            params?: any;
            query?: any;
        }) {
            this.$router.push({ name, params, query });
        },
        async deleteReport(id: string) {
            this.isDeleting = true;
            try {
                const res = await ReportService.deleteReport(id);
                console.log(res);
                this.$bvToast.toast(res.data.message, {
                    title: "Success!",
                    variant: "success",
                    solid: true,
                });
                this.$emit("deleted");
            } catch (error) {
                //
            } finally {
                this.isDeleting = false;
            }
        },
        async fetchFeedback() {
            try {
                this.isLoading = true;

                const res = await ReportService.fetchPreview(this.report.id);

                this.previewText = res.data;
            } catch (error) {
                this.$bvToast.toast("Preview fetch failed!", {
                    title: "Error!",
                    variant: "danger",
                    solid: true,
                });
            } finally {
                this.isLoading = false;
            }
        },
    },
});
