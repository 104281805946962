var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"report-item px-5 py-4 mb-3 bg-white rounded"},[_c('b-row',[_c('b-col',{attrs:{"cols":"9"}},[_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"cols":"4"}},[_c('div',[_c('div',{staticClass:"text-sm-2"},[_vm._v("Report ID")]),_c('div',{staticClass:"text-black text-sm-2"},[_vm._v(" "+_vm._s(_vm.report.reportCode)+" ")])])]),_c('b-col',{attrs:{"cols":"4"}},[_c('div',[_c('div',{staticClass:"text-sm-2"},[_vm._v("Report Timeline")]),_c('div',{staticClass:"text-black text-sm-2"},[_vm._v(" "+_vm._s(!_vm.report.reportingTimeLineFrom || !_vm.report.reportingTimeLineTo ? "Not provided" : ((_vm.formatDate( _vm.report.reportingTimeLineFrom )) + " - " + (_vm.formatDate( _vm.report.reportingTimeLineTo ))))+" ")])])]),_c('b-col',{attrs:{"cols":"4"}},[_c('div',[_c('div',{staticClass:"text-sm-2"},[_vm._v("Company")]),_c('div',{staticClass:"text-black text-sm-2"},[_vm._v(" "+_vm._s(_vm.report.companyName)+" ")]),_c('div',{staticClass:"text-black text-sm-2"},[_vm._v(" ("+_vm._s(_vm.report.rcNumber)+") ")])])])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('div',[_c('div',{staticClass:"text-sm-2"},[_vm._v("Report Title")]),_c('div',{staticClass:"text-black text-sm-2"},[_vm._v(" "+_vm._s(_vm.report.reportTitle)+" ")])])]),_c('b-col',{attrs:{"cols":"4"}},[(_vm.report.dateSentToRegulator)?_c('div',[_c('div',{staticClass:"text-sm-2"},[_vm._v("Date Submitted")]),_c('div',{staticClass:"text-black text-sm-2"},[_vm._v(" "+_vm._s(_vm.formatDate( _vm.report.dateSentToRegulator, _vm.DateTime.DATETIME_SHORT ))+" ")])]):_vm._e()]),_c('b-col',{attrs:{"cols":"4"}},[(
                            _vm.report.regulatorPushCount > 0 &&
                            _vm.report.reportStatus ===
                                _vm.IReportStatus.REGULATOR_APPROVAL_IN_PROGRESS
                        )?_c('div',[_c('div',{staticClass:"text-sm-2"},[_vm._v("Current desk")]),_c('div',{staticClass:"text-black text-sm-2"},[_vm._v(" "+_vm._s(_vm.report.currentDeskUser ? ("" + (_vm.report.currentDeskUser.fullName)) : "Not sent")+" "),_c('br'),_vm._v(" "+_vm._s(_vm.report.currentDeskUser ? ("(" + (_vm.report.currentDeskUser.email) + ")") : "")+" ")])]):_vm._e()])],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"text-right mb-3"},[_c('b-badge',{staticClass:"px-3 py-2",class:("text-" + (_vm.badgeColor.text)),attrs:{"pill":"","variant":_vm.badgeColor.bg}},[_vm._v(_vm._s(_vm.reportStatus))])],1),_c('div',{staticClass:"text-right text-black"},[_c('b-badge',{staticClass:"px-3 py-2",class:'text-primary',attrs:{"pill":"","variant":'blue-100'}},[_vm._v("Principles Score: "+_vm._s(28 - _vm.report.flagCount)+"/28")])],1),(_vm.showActions)?_c('div',{staticClass:"d-flex align-items-center justify-content-end mt-3"},[_c('div',{staticClass:"pointer text-primary font-weight-bold",on:{"click":function($event){return _vm.goTo({
                            name: _vm.HOME.VIEW_REPORT.NAME,
                            params: { id: _vm.report.id },
                        })}}},[_vm._v(" View Details ")]),_c('div',[_c('b-dropdown',{attrs:{"disabled":_vm.isLoading,"variant":"transparent","no-caret":"","menu-class":" my-0 py-0"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [(!_vm.isLoading)?_c('b-icon',{attrs:{"icon":"three-dots-vertical"}}):_c('b-spinner',{staticClass:"mr-2",attrs:{"label":"Loading...","small":""}})]},proxy:true}],null,false,291430548)},_vm._l((_vm.itemOptions),function(itemOption,ix){return _c('b-dropdown-item',{key:ix,staticClass:"my-0",on:{"click":function($event){return itemOption.action(_vm.report)}}},[_c('div',{staticClass:"py-3"},[_vm._v(_vm._s(itemOption.text))])])}),1)],1)]):_vm._e()]),_c('b-col',[_c('feedback-preview-modal',{attrs:{"id":_vm.report.id,"report":_vm.report,"text":_vm.previewText,"isLoading":_vm.isLoading}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }