var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ba-loader-wrapper',{attrs:{"isLoading":_vm.isLoading}},[_c('div',{staticClass:"py-3 px-4"},_vm._l((_vm.report.documents),function(document,ix){return _c('ba-detail-section',{key:ix,staticClass:"mx-0 mb-5",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('p',[_vm._v(_vm._s(document.name))])]},proxy:true},{key:"description",fn:function(){return [_c('div',{staticClass:"mt-4 d-flex align-items-center"},[(_vm.showButtons)?_c('b-button',{staticClass:"text-white px-3 py-2 mr-2",staticStyle:{"font-size":"11px"},attrs:{"variant":"info"},on:{"click":function($event){return _vm.selectSection(document)}}},[_vm._v(" "+_vm._s(document.flagReason ? "Update" : "Add")+" Comment ")]):_vm._e(),(
                            _vm.flaggedSections[_vm.mapped[document.name]]
                                .flagReason
                        )?_c('b-button',{staticClass:"text-white px-3 py-2",staticStyle:{"font-size":"11px"},attrs:{"variant":"warning"},on:{"click":function($event){return _vm.sendFlaggedProps(
                                'View comment on document',
                                _vm.flaggedSections[_vm.mapped[document.name]]
                                    .flagReason
                            )}}},[_vm._v("View Comment")]):_vm._e()],1)]},proxy:true},{key:"body",fn:function(){return [(document.attatchments.length === 0)?_c('div',{staticClass:"text-black text-sm-3"},[_vm._v(" Document Not Provided ")]):_c('div',_vm._l((document.attatchments),function(attachment,idx){return _c('ba-file-input',{key:idx,attrs:{"noLabel":"","noDivider":"","readonly":"","fileName":attachment.fileName,"fileId":attachment.docUploadId,"size":attachment.size}})}),1)]},proxy:true}],null,true)},[(
                    _vm.report.documents.length > 0 &&
                    ix !== _vm.report.documents.length - 1
                )?_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]):_vm._e()],1)}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }