




































































import Vue from "vue";
import QuestionOutput from "@/app/components/reports/QuestionOutput.vue";
import FlagPrincipleModal from "@/app/components/modals/FlagPrincipleModal.vue";

import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        report: {
            type: Object,
            default: () => ({}),
        },
        canDownload: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        QuestionOutput,
        FlagPrincipleModal,
    },
    data() {
        return {
            pLoading: false,
            loading: false,
            selectedData: {
                principle: {},
                mappedInputModel: {},
            } as any,
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/getUserData",
        }),
        showButtons(): boolean {
            return (
                this.report?.reportInformation?.currentDeskUser?.email ===
                this.user.email
            );
        },
    },
    methods: {
        ...mapActions({
            fetchReport: "reports/fetchReportById",
            flagPrinciple: "reports/flagPrinciple",
        }),
        sendFlaggedProps(value: { description: string; comment: string }) {
            this.$emit("clicked", value);
        },
        async fetchReportById() {
            const { id } = this.$route.params;
            this.pLoading = true;
            try {
                await this.fetchReport(id);
            } catch (error) {
                this.pLoading = false;
            } finally {
                this.pLoading = false;
            }
        },
        hideModal() {
            this.$bvModal.hide(`modal-principle`);
            this.fetchReportById();
        },
        refreshReport() {
            this.fetchReportById();
        },
        async resolveFlagPrinciple({
            entryId,
            isFlagged,
        }: {
            entryId: string;
            isFlagged: boolean;
        }) {
            const body = {
                entryId,
                isFlagged,
            };
            try {
                this.loading = true;
                const res = await this.flagPrinciple({
                    id: this.$route.params.id as string,
                    body,
                });

                this.$bvToast.toast(res.message, {
                    title: "Success!",
                    variant: "success",
                    solid: true,
                });
                await this.fetchReportById();
            } catch (error) {
                this.loading = false;
            } finally {
                this.loading = false;
            }
        },
        selectQuestion(val: any) {
            this.selectedData = { ...val };
            setTimeout(() => {
                this.$bvModal.show(`modal-principle`);
            }, 500);
        },
    },
});
